import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from 'react-modal';

const App = () => {
  const [grade, setGrade] = useState("");
  const [address, setAddress] = useState("");
  const [distance, setDistance] = useState(null);
  const [gradeScore, setGradeScore] = useState(null);
  const [distanceScore, setDistanceScore] = useState(null);
  const [totalScore, setTotalScore] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const baseLocation = { lat: 37.4512348695085, lng: 127.12939432624 };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_API_KEY}&libraries=services`;
    script.async = true;
    document.head.appendChild(script);
  }, []);

  const handleAddressSearch = async () => {
    if (!address) return;

    try {
      const response = await axios.get(
        `https://dapi.kakao.com/v2/local/search/address.json?query=${address}`,
        {
          headers: {
            Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_API_KEY}`,
          },
        }
      );

      const { documents } = response.data;

      if (documents.length === 0) {
        alert("주소를 찾을 수 없습니다.");
        return;
      }

      if (documents.length > 1) {
        alert("검색 결과가 많습니다. 도로명주소 또는 지번주소를 상세히 입력해주세요.");
        return;
      }

      const { x, y } = documents[0].address;
      const userLocation = { lat: parseFloat(y), lng: parseFloat(x) };
      const dist = calculateDistance(baseLocation, userLocation);

      setDistance(dist);
      setApiResponse(documents[0]);
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const calculateDistance = (loc1, loc2) => {
    const R = 6371e3; // metres
    const φ1 = (loc1.lat * Math.PI) / 180;
    const φ2 = (loc2.lat * Math.PI) / 180;
    const Δφ = ((loc2.lat - loc1.lat) * Math.PI) / 180;
    const Δλ = ((loc2.lng - loc1.lng) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d / 1000; // in kilometres
  };

  const calculateScores = () => {
    const maxDistance = 315; // maximum distance corresponding to 100 points
    const gradeMax = 4.5; // maximum grade corresponding to 100 points
    const calculatedGradeScore = (parseFloat(grade) / gradeMax) * 100;
    const calculatedDistanceScore = distance > maxDistance ? 100 : (distance / maxDistance) * 100;
    const totalScore = calculatedGradeScore + calculatedDistanceScore;

    setGradeScore(calculatedGradeScore.toFixed(2));
    setDistanceScore(calculatedDistanceScore.toFixed(2));
    setTotalScore(totalScore.toFixed(2));
  };

  const handleGradeChange = (e) => {
    const value = parseFloat(e.target.value);
    if (value < 0 || value > 4.5) {
      alert("성적은 0.00부터 4.50 사이의 값이어야 합니다.");
      setGrade("");
      return;
    }
    setGrade(e.target.value);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="container mt-5">
      <style jsx>{`
        .btn-purple {
          background-color: purple;
          color: white;
        }
        .btn-purple:hover {
          color: white;
        }
        .btn-success {
          background-color: green;
          color: white;
        }
        .ml-2 {
          margin-left: 8px;
        }
        .ReactModal__Overlay {
          background-color: rgba(0, 0, 0, 0.5) !important;
        }
        .ReactModal__Content {
          width: 50%;
          height: 70%;
          margin: auto;
          padding: 20px;
          overflow-y: auto;
        }
      `}</style>
      <h1 className="text-center mb-4">가천대학교 생활관<br/>입사컷 산출 계산기</h1>
      <div className="row justify-content-center">
        <div className="col-md-5 box">
          <h4 className="text-center">성적</h4>
          <input
            type="number"
            className="form-control"
            value={grade}
            onChange={handleGradeChange}
            placeholder="지난 학기 평점을 입력해주세요 (0.00 - 4.50)"
            min="0.00"
            max="4.50"
            step="0.01"
          />
        </div>
        <div className="col-md-5 box">
          <h4 className="text-center">거리</h4>
          <input
            type="text"
            className="form-control"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            onBlur={handleAddressSearch}
            onKeyDown={(e) => e.key === 'Enter' && handleAddressSearch()}
            placeholder="도로명주소(지번주소)를 입력해주세요"
          />
          {apiResponse && (
            <div className="mt-3">
              <p><strong>Address:</strong> {apiResponse.address.address_name}</p>
              <p><strong>Road Address:</strong> {apiResponse.road_address?.address_name}</p>
              <p><strong>Building Name:</strong> {apiResponse.road_address?.building_name}</p>
            </div>
          )}
        </div>
      </div>
      <div className="text-center mt-4">
        <button className="btn btn-primary" onClick={calculateScores}>계산하기</button>
        <button className="btn btn-success ml-2" onClick={openModal}>산출 방법</button>
        <a href="https://forms.gle/Thuhc32teFKhjZ6Q7" className="btn btn-purple ml-2" target="_blank" rel="noopener noreferrer">점수 집계 설문조사</a>
      </div>
      {totalScore !== null && gradeScore !== null && distanceScore !== null && (
        <div className="text-center mt-4">
          <h4>성적 {gradeScore}점 + 거리 {distanceScore}점</h4>
          <h3>총합 {totalScore} / 200점</h3>
          <p><strong>&lt; 산출 근거 &gt;</strong></p>
          <p>학점 {parseFloat(grade).toFixed(2)} / 4.50 → 환산 점수 {gradeScore} / 100점</p>
          <p>거리 {distance.toFixed(2)} km → 환산 점수 {distanceScore} / 100점</p>
        </div>
      )}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="산출 방법" ariaHideApp={false}>
        <h2>산출 방법</h2>
        <p><strong>학점 → 점수</strong><br />
        평점 4.50을 100점 만점으로 하여 0.00까지 균등하게 나누어 점수를 부여합니다.</p>
        <p><strong>거리 → 점수</strong><br />
        가천대학교 글로벌캠퍼스를 기준으로 하여 입력한 주소지까지의 직선 거리를 계산합니다. 이때, 315.00km가 넘는 경우 100점을 부여하며 그 미만의 거리까지 100점을 균등 부여합니다.</p>
        <button className="btn btn-secondary" onClick={closeModal}>닫기</button>
      </Modal>
    </div>
  );
};

export default App;
